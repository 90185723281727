<ng-container *ngIf="tools">
    <div class="row  helms-tools-order">
        <div class="col-8">
            <app-table [autoSizeFirstColumn]="true">
                <thead>
                    <tr>
                        <th></th>
                        <th><span i18n>Tools</span>:</th>
                        <th><span i18n>Stock</span>:</th>
                        <th class="text-right"><span i18n>Price in DKK</span>:</th>
                    </tr>
                </thead>
                <tbody infinite-scroll (scrolled)="loadAdditionalTools()">
                    <tr *ngFor="let tool of tools">
                        <td>
                            <button (click)="onAdd(tool)" class="helms-tool-order__button  helms-tool-order__button--in-table  btn  btn-primary  text-white">+</button>
                        </td>
                        <td>
                            <ng-container *ngIf="tool?.attachments && tool.attachments.length > 0">
                            <button class="helms-tool-order__button  helms-tool-order__button--in-table  btn  btn-link" (click)="openImage(image, tool.id)">
                                <fa-icon [icon]="faCamera"></fa-icon>
                            </button>
                            </ng-container>
                            {{tool.name}}
                            <button class="helms-tool-order__button  helms-tool-order__button--in-table  btn  btn-link  float-right"
                                    title="Se alle Kompatible modeller"
                                    (click)="openComparison(compatibility, tool)"
                                    ngbTooltip="Se alle Kompatible modeller">
                                <fa-icon [icon]="faListCheck"></fa-icon>
                            </button>
                        </td>
                        <td>{{tool.product_stocks.length}}</td>
                        <td class="text-right">
                            <ng-container *ngIf="tool.prices && tool.prices.length > 0">
                                {{tool.prices[0].price | currency:'DKK'}}
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </app-table>
            <app-loader *ngIf="isFetchingMoreTools"></app-loader>
            <button *ngIf="canLoadmore()" (click)="loadAdditionalTools()" [disabled]="isFetchingMoreTools" class="btn  btn-sm  btn-primary" i18n>Load more</button>
        </div>
        <div class="col-4">
            <div [className]="'sticky-top  helms-tool-order__basket' + (!offerService.selectedExtraTools || offerService.selectedExtraTools.length === 0 ? '  helms-tool-order__basket--empty' : '')">
                <p class="h6  heading  mb-0"><span i18n>Selected tools</span>:</p>
                <ul class="list-group  mb-2">
                    <li class="list-group-item  d-flex  justify-content-between  align-items-center" *ngFor="let orderLine of offerService.selectedExtraTools">
                        <div class="QuantitySelector  d-flex  mr-3">
                            <button (click)="onRemove(orderLine.product)" class="QuantitySelector__btn  QuantitySelector__btn--remove  btn  btn-primary  button  button--table">-</button>
                            <span class="QuantitySelector__count  badge  badge-secondary  ml-1  mr-1" [ngClass]="{'badge-secondary': orderLine.quantity > 0}">{{orderLine.quantity}}</span>
                            <button (click)="onAdd(orderLine.product)" class="QuantitySelector__btn  QuantitySelector__btn--remove btn  btn-primary  button  button--table">+</button>
                        </div>
                        <small class="text-right">{{orderLine.product.name}}</small>
                    </li>
                </ul>
                <button class="btn btn-primary btn-block" href (click)="offerService.nextStep()" i18n>Order tools</button>
            </div>
        </div>
    </div>
</ng-container>
<app-loader *ngIf="!tools"></app-loader>

<ng-template #compatibility let-modal>
    <div class="modal-header">
        <h4 class="modal-title" i18n>Compatible models</h4>
        <button type="button" class="btn  btn-outline-danger  btn-close" aria-label="Close" (click)="modal.dismiss()">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
    <div class="modal-body">
        <div class="helms-tool-order__comparison" *ngIf="!loadingComparison && allProducts && toolWithRelation">
            <div *ngFor="let product of allProducts" [className]="isCompatible(product.id) ? 'text-success' : 'text-danger'">
                <fa-icon [icon]="isCompatible(product.id) ? faSquareCheck : faSquare"></fa-icon> {{product.name}}
            </div>
        </div>
        <app-loader *ngIf="loadingComparison"></app-loader>
    </div>
</ng-template>

<ng-template #image let-modal>
    <div class="modal-body">

        <ng-container *ngIf="!productImageURL"><span i18n>Loading...</span></ng-container>
        <img [src]="productImageURL" alt="Image" class="img-responsive" *ngIf="productImageURL"/>

    </div>
</ng-template>
